import {LatLngExpression} from "leaflet";

export const THEMES = {
  LIGHT: 'LIGHT',
};

export const LINK_PRIVACY = "https://www.iubenda.com/privacy-policy/87466855"
export const LINK_COOKIE_POLICY = "https://www.iubenda.com/privacy-policy/87466855/cookie-policy"
export const LINK_TERMS = "https://www.iubenda.com/termini-e-condizioni/87466855"


export const WSS_URL = "wss://hub.retemet.com/mqtt"

export const dimensioniMarker = 30

export const latBoa = 45.788488
export const lngBoa = 8.401226

export const URL_WEBCAM_AORTA = "https://www.meteolivevco.it/webcam/Orta.jpg";
export const BOA_LAGO: LatLngExpression = [
  latBoa, lngBoa
]