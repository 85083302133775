import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    }
  )
);

const DashboardLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => (
    {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: '64px',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '280px'
      }
    }
  )
);

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
    const [isDisclaimerOpen, setIsDisclaimerOpen] = useState<boolean>(false);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
      />
      <DashboardSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
        onDisclaimerOpened={(): void => setIsDisclaimerOpen(true)}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
        <Dialog
            open={isDisclaimerOpen}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Info"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {
                        <>I dati pubblicati nella piattaforma sono acquisizioni dirette e in
                            tempo reale e non sono quindi sottoposti a validazione. Hanno funzione informativa, il CNR IRSA e i partner declinano ogni responsabilità per l’uso improprio dei dati.
                            Per informazioni: <br></br>segreteria-vb@irsa.cnr.it
                            <br></br>ecomuseo@lagodorta.net
                        </>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setIsDisclaimerOpen(false);
                }} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
